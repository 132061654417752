@import "../../euphoria/layout/columns";

.columns {
	&.two {
        @include basic-columns(
            $count: 2,
            $dimensions: (48%, 52%),
            $gutter: ( $magenta-margin, 6% ),
            $breakpoint: tablet-portrait
        );
	}

    &.two_reverse {
        @include basic-columns(
            $count: 2,
            $dimensions: (48%, 52%),
            $gutter: ( $magenta-margin, 6% ),
            $float: right,
            $breakpoint: phone-landscape
        );
    }

	&.wide_slim {
        @include basic-columns(
            $count: 2,
            $dimensions: (45%, 55%),
            $gutter: ( $light-blue, 11% ),
            $breakpoint: phone-landscape
        );
    }

    &.wide_slim_cta {
        @include basic-columns(
                $count: 2,
                $dimensions: (27%, 73%),
                $gutter: ( $red-margin, 3% ),
                $breakpoint: phone-landscape
        );
    }

    &.wide_slim_reverse {
        @include basic-columns(
            $count: 2,
            $dimensions: (45%, 55%),
            $gutter: ( $light-blue, 11% ),
            $float: right,
            $breakpoint: tablet-portrait
        );
    }

    &.wide_slim_reverse_cta{
        @include basic-columns(
                $count: 2,
                $dimensions: (27%, 73%),
                $gutter: ( $red-margin, 3% ),
                $float: right,
                $breakpoint: tablet-portrait
        );
    }

	&.two-uneven {
		&.wide-slim {
			@include basic-columns(
				$count: 2,
				$dimensions: (67%, 33%),
				$breakpoint: tablet-portrait
			);
		}

		&.slim-wide {
			@include basic-columns(
				$count: 2,
				$dimensions: (33%, 67%),
				$breakpoint: tablet-portrait
			);
		}

		&.superslim-wide {
			@include basic-columns(
				$count: 2,
				$dimensions: (24%, 76%),
				$breakpoint: tablet-portrait
			);
		}

		&.wide-superslim {
			@include basic-columns(
				$count: 2,
				$dimensions: (76%, 24%),
				$breakpoint: tablet-portrait
			);
		}
	}

	&.three {
		@include basic-columns(
			$count: 3,
			$breakpoint: phone-landscape
		);

		&.cta {
			@include basic-columns(
				$count: 3,
				$dimensions: ( 27%, 46%, 27% ),
				$gutter: ( $red-margin, 3% ),
				$gutter-bottom: 0,
				$breakpoint: phone-landscape
			);
		}
	}

	&.four {
		@include basic-columns(
			$count: 4,
			$breakpoint: tablet-portrait
		);

		@include breakpoint(tablet-portrait) {
			@include basic-columns(
				$count: 2,
				$breakpoint: phone-landscape
			);
		}
	}
}
