header {
    &.static-text {
        position: relative;

        .image-wrapper,
        .text-wrapper {
            box-sizing: border-box;
        }

        .image-wrapper {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            .fragment {
                height: 100%;

                .columns {
                    height: 100%;

                    .fg-element {
                        height: 100%;

                        .swiper-container {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .text-wrapper {
            position: relative;
            pointer-events: none;
            z-index: 3;

            .fragment {
                background: transparent;
                pointer-events: none;
                padding-top: 6em;
                padding-bottom: 6em;

                .columns {
                    display: flex;
                    flex-direction: row;
                }

                .fg-element {
                    pointer-events: all;
                    box-sizing: border-box;
                    display: inline-block;
                    position: relative;
                    padding: $pink-margin $pink-margin;
                    //background: $region-cta-background-color;
                    //box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
                    border-radius: 5px;
                    color: $region-cta-text-color;

                    &.mod-text {
                        background-color: rgba($theme-secondary-color, .9);
                        margin-right: auto;
                        margin-left: auto;
                    }

                    h1 {
                        color: #fff;
                        margin: 0;

                        + h1, + h2, + h3, + h4, + h5, + h6, + p, + ul, + ol {
                            margin-top: .14em;
                        }
                    }
                    h2, h3, h4, h5, h6 {
                        color: $region-cta-head-color;
                        margin: 0;

                        + h1, + h2, + h3, + h4, + h5, + h6, + p, + ul, + ol {
                            margin-top: .14em;
                        }
                    }

                    a {
                        color: $region-cta-link-color;
                        text-decoration: underline;
                    }

                    &.empty {
                        background: rgba($theme-secondary-color, .15);
                    }
                }
            }
        }
    }
}