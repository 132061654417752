@keyframes beating {
    from {
        transform: scale( 1 );
    }
    to {
        transform: scale( 1.1 );
    }
}

@function set-color-for-background-color($color) {
	@if (lightness($color) > 54) {
		@return $font-color;
	} @else {
		@return #fff;
	}
}
