.region {
	border: $border-shadow-color;
	border-width: 1px 0;
	padding: 4.2em 0;

	&.dark {
		background: $fragment-dark-color;

        .element-template--boxed {
            background: #fff;
        }
	}

	&.cta {
		background: $theme-primary-color;
	}

	&.call-to-action {
        position: relative;
        background: $region-cta-background-color;
        color: $region-cta-text-color;
        margin-top: -$fragment-spacing;

        h1, h2, h3, h4, h5, h6 {
            color: $region-cta-head-color;
            margin-top: .5em;
            margin-bottom: .5em;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

		a {
			color: $region-cta-link-color;
            text-decoration: underline;
			&.button {
				border:2px solid #fff;
				&:hover {
					background:#fff !important;
					color:$theme-primary-color !important;
				}
			}
		}

        .content {
            max-width: calc( 900px + 2em );
        }

        .fg-element {
            &.empty {
                background-color: rgba( $theme-primary-color, .05);
            }
        }

        &.with-bg-image {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: -$fragment-spacing;
            z-index: 1;

            .element-template--banner .image {
                opacity: .025;
            }

            .front {
				position:relative;
                padding: 3.2em 0;
            }
        }
	}

	&:first-child, .full-width + &, &.region + .region {
		border-top-width: 0;
	}

	&:last-child {
		border-bottom-width: 0;
	}

	& + .full-width, & + .full-width-2-col, & + .full-width-2-col-alt {
		background: #fff;
		margin-top: -1px;
		position: relative;
		z-index: 1;
	}

	&.dark {
		& + .full-width, & + .full-width-2-col, & + .full-width-2-col-alt {
			background: $fragment-dark-color;
		}

		.mod-links li {
			background: #fff;
		}
	}

	.fragment {
		background: transparent;
	}

	.full-width + &, .full-width-2-col + &, .full-width-2-col-alt + & {
		margin-top: -$fragment-spacing;
	}
}
