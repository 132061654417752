.mod-text {
	&.element-template--default {

	}

	&.element-template--boxed {
		background: #f8f8f8;
		//border: 1px solid $border-shadow-color;
		box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
		border-radius: 3px;
		padding: 2em 1.6em;
	}
	&.element-template--headertext {
		padding:0 !important;
		position: relative !important;
		bottom: -3em;
	}
}
