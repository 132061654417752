$form-error-color: #DF6565 !default;
$form-fieldset-border-color: #ededed !default;
$form-input-background-color: #fff !default;
$form-input-border: 1px solid rgba( #000, 0.14 ) !default;
$form-input-border-radius: 0 !default;
$form-input-color: #333 !default;
$form-input-padding: .8333em 1em !default;
$form-input-placeholder-color: #BBB !default;
$form-input-required-placeholder-color: #898989 !default;
$form-select-background-color: #ffffff !default;
$form-select-border-radius: 0 !default;

input, textarea, select {
	background-clip: padding-box;
	border: 1px solid transparent;
	border-radius: 0;
	box-sizing: border-box;
	display: inline-block;
	font: inherit;
	margin: 0;
	padding: $form-input-padding;
	text-decoration: none;
	line-height: 1;
	-ms-touch-action: auto;
	touch-action: auto;
	
	&:focus { outline: none; }
}

input[type="text"], input[type="password"], input[type="email"], textarea, select {
	background-color: $form-input-background-color;
	border: $form-input-border;
	border-radius: $form-input-border-radius;
	color: $form-input-color;
	line-height: normal;
	width: 100%;
	
	&::-webkit-input-placeholder {
		color: $form-input-placeholder-color;
		font: inherit;
		font-weight: normal;
	}
	
	&::-moz-placeholder {  /* Firefox 19+ */
		color: $form-input-placeholder-color;
		font: inherit;
		font-weight: normal;
	}
	
	&:-ms-input-placeholder {  
		color: $form-input-placeholder-color;
		font: inherit;
		font-weight: normal;
	}
}

.form-field-required {
	input[type="text"], input[type="password"], input[type="email"], textarea {
		&::-webkit-input-placeholder {
			color: $form-input-required-placeholder-color;
			font: inherit;
			font-weight: normal;
		}

		&::-moz-placeholder {  /* Firefox 19+ */
			color: $form-input-required-placeholder-color;
			font: inherit;
			font-weight: normal;
		}

		&:-ms-input-placeholder {
			color: $form-input-required-placeholder-color;
			font: inherit;
			font-weight: normal;
		}
	}
}

textarea {
	line-height: inherit;
}

select {
	background: #f9f9f9 url('../images/form/select-icon.png') no-repeat;
	background-color: $form-select-background-color;
	background-position: 96% 47%;
	border-radius: $form-select-border-radius;
	appearance: none;
} 

form {
	fieldset {
		border: none;
		border-top: 1px solid $form-fieldset-border-color;
		padding: 0;
		margin: 1em 0 2em;
		
		legend {
			color: $theme-primary-color;
			font-size: 1.15em;
			margin: 0 0 .6em 0;
			padding: 0 1em 0 0;
		}
	}

	label {
		font-weight: 600;
	}

	.form-field {
		margin: 0 0 1em;
	}
	
	.radiobutton {
		label { font-weight: inherit; }
		> label > input { margin: 0 .4em; }
	}
	
	.phonenumberinput {
		* { display: inline-block; margin-right: 2%; }
		span { display: none; }
		label { display: block; height: auto; }
		select { width: 30%; }
		.phonenumberinput_areacode { width: 20%; }
		.phonenumberinput_number { width: 43%; margin: 0; }
	}

	.form-field-date {
		@include clearfix;

		label {
			display: block;
		}

		select {
			display: block;
			float: left;
			margin: 0 1em 0 0;
			width: calc(33% - .67em);

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.mod_contactform_required {
	font-size: .8em;
	opacity: .6;
}

.field {
	margin: 0 0 1em;
	
	&.date {
		position: relative;
		
		&:after {
			position: absolute;
			right: 1em;
			top: 50%;
			margin-top: -.6em;
			
			color: #aaa;
			content: '\f133';
			font-size: 1em;
			font-family: fontawesome;
			pointer-events:none;
		}
	}
}

.error, form .error.not-empty {
	background: none;
	color: $form-error-color;
	display: block;
	float: none;
	font-size: .78em;
	line-height: 1.2;
	margin: .5em 0 0;
	padding: 0;
}

/*target Internet Explorer 9 and Internet Explorer 10:*/
@media screen and (min-width:0\0) { 
    select {
        background:none;
        padding: 5px;
    }
}

.form-field-datetime {
	select[name*="day"],
	select[name*="month"],
	select[name*="year"],
	select[name*="hour"],
	select[name*="minute"] {
		width: auto;
		margin: 0 .15em;
	}
}

.form-field-captcha {
	width: 35%;

	label {
		display: block;
	}

	input {
		background-color: rgba( $theme-secondary-color, .1 );
	}
}
