// Override scroll effects to show all content when the responsive menu is open to prevent showing empty content areas.
.effects-scroll.euphoria-menu-active #page-wrapper {
	.fragment, .footer-row {
		&, & .column {
			opacity: 1 !important;
		}
	}
}

body .euphoria-menu {
	@include responsive-menu($side: right);
	background-color: $theme-primary-color;
	font-size: 1.2em;

	.euphoria-menu-button {
		background: transparent;
		@include responsive-menu-button(
				$side: right,
				$style: square,
				$breakpoint: tablet-landscape,
				$color: $font-color,
				$active-color: set-color-for-background-color($theme-primary-color)
		);

		float: right;
		margin: .4em 1em;
	}

	.mod-menu {
		clear: both;

		li {
			border-width: 0;

			&.active {
				//background: set-color-for-background-color($theme-primary-color);

				> a {
					color: #fff;
					font-weight: bold;
				}
			}

			a:active {
				color: inherit;
			}
		}

		li ul {
			background: rgba(#000, .2);
			padding: .4em 0;

			li {
				padding: 0;
			}
		}

		.expand {
			background: set-color-for-background-color($theme-primary-color);
			border-radius: 100px;
			padding: .87em .8em !important;
			right: .8em;
			top: .3em;
			height: 1.8em;
			width: 1.8em;
		}
	}
}

.euphoria-menu-overlay {
	@include responsive-menu-overlay($color: rgba(#000, .4));
}