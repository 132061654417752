@import "../../euphoria/responsive/menu";

@mixin logo-height($ratio, $headerHeight) {
	$height: ($headerHeight * $ratio);
	height: $height !important;
	margin: -1 * (($height - $headerHeight)/2);
}

body.header-sticky {
	padding-top: 5.263em;

	@include breakpoint(tablet-landscape) {
		padding-top: 3.75em;
	}

	&.menu-orientation-left, &.menu-orientation-right {
		@media only screen and (min-width: 1170px) {
			padding-top: 0;
		}
	}

	#header {
		position: fixed;
	}

	&.logged-in #header {
		top: 49px;
	}

	#header-cta {
		transform: none !important;
	}
}

#header {
	@include clearfix;
	background: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(#000, .14);
	border-width: 0 0 1px;
	box-sizing: border-box;
	height: 5.263em;
	padding: calc(1.579em/2) 0;
	position: relative;
	transform: translate3d(0px, 0px, 0px);
	left: 0;
	right: 0;
	top: 0;
	z-index: 100;
	pointer-events: none;

	.content {
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		@include clearfix;
		pointer-events: all;
		height: 100%;
	}

	.logo {
		display: block;

		&, * {
			height: 100%;
		}

		@if $logo-height != null {
			@include logo-height($logo-height, 2.105em);
		}

		img {
			display: block;
			max-width: 100%;

			// Reduce pixeled appearance of logo in IE & Firefox
			-ms-interpolation-mode: bicubic;
			background:rgba(255,255,255,.001);
			image-rendering: auto;
		}
	}

	@include breakpoint(tablet-landscape) {
		height: 3.75em;
		padding: .75em 0;

		.logo {
			@include logo-height(1, 2.25em);
		}

		.euphoria-menu-button {
			background: transparent;
			float: right;
			margin-left:auto;

			@include responsive-menu-button(
				$side: right,
				$style: square,
				$breakpoint: tablet-landscape,
				$color: $font-color,
				$active-color: set-color-for-background-color($theme-primary-color)
			);
		}

		.no-js & {
			height: auto;

			.mod-menu {
				clear: both;
				padding-top: 1em;
				display: block;
				float: none;
				margin: 0;
			}

			.euphoria-menu-button {
				display: none;
			}

		}
	}

	.mod-menu {
		font-weight: 700;
		margin: -1.579em 0;
		margin-left:auto;
		@include breakpoint(tablet-landscape) {
			display: none;
		}
	}
}

.menu-orientation-left #header, .menu-orientation-right #header {
	@media only screen and (min-width: 1170px) {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		height: auto;
		width: 330px;
		border-width: 0 1px 0 0;

		.logo, .mod-menu {
			float: none;
			height: auto;
		}

		.content {
			padding: 0 2.1em;
		}

		.mod-menu {
			margin: 2.368em -2.1em 0;
		}
	}
}

/*:not(.header-cta-scroll-offset-reached).has-header-cta.menu-orientation-left &, :not(.header-cta-scroll-offset-reached).has-header-cta.menu-orientation-right & {
	@media only screen and (min-width: 1170px) {
		top: 2.8em;
	}
}*/

.menu-orientation-left {
	@media only screen and (min-width: $layout-width) {
		padding-left: 330px;
	}
}

.menu-orientation-right {
	@media only screen and (min-width: $layout-width) {
		padding-right: 330px;

		#header {
			border-width: 0 0 0 1px;
			left: auto;
			right: 0;
		}
	}
}