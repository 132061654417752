#posts {
    .button-wrapper {
        @include clearfix;

        .addthis_inline_share_toolbox {
            display: inline-block;
            float: right;
        }
    }

    h2, h2 a {
        color: $theme-primary-color;
        margin-bottom: 0;
        text-decoration: none;
    }

    time {
        display: block;
        font-size: .722em;
        font-weight: bold;
        text-transform: uppercase;
        margin: $red-margin/2 0;
    }

    .blog-post {
        box-sizing: border-box;
        position: relative;
        display: table;
        table-layout: fixed;
        box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
        margin-bottom: $orange-margin;

        @include basic-two-columns(
            $left-width:  57%,
            $right-width: 43%,
            $gutter: (0,0),
            $gutter-bottom: 0,
            $breakpoint: phone-landscape
        );

        .column:nth-child(1n) {
            box-sizing: border-box;
            position: relative;
            float: none;
            display: table-cell;
            vertical-align: top;

            &.text-wrapper {
                padding: $pink-margin $pink-margin $pink-margin $orange-margin;
            }

            &.image-wrapper {
                overflow: hidden;
                height: 100%;

                a {
                    display: block;
                    position: absolute;
                    top: 0; bottom: 0;
                    right: 0; left: 0;
                    width: 100%;
                    height: 100%;
                }

                .image {
                    height: 100%;
                    transform-origin: center;
                    transform: scale( 1 ) rotate( 0deg );
                    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
                    opacity: .95;
                    background: {
                        size: cover;
                        position: center;
                        repeat: no-repeat;
                    };
                }
            }
        }

        &:hover {
            .column.image-wrapper .image {
                opacity: 1;
                transform: scale(1.08) rotate(.06deg);
            }
        }
    }

    .view-blog-post {
        @include basic-columns(
            $selector: '.wrapper',
            $count: 2,
            $dimensions: (48%, 52%),
            $gutter: ( $magenta-margin, 6% ),
            $float: right,
            $breakpoint: phone-landscape
        );

        .wrapper {
            position: relative;
            box-sizing: border-box;

            &.images {
               .main-image {
                   position: relative;
                   overflow: hidden;

                   img {
                       display: block;
                       max-width: 100%;
                       height: auto;
                       border-radius: 5px;
                       backface-visibility: hidden;
                   }
               }
            }

            &.message {

            }
        }
    }
}