@import "../euphoria/buttons";
@import "../euphoria/responsive/breakpoints";

%body-font {
    color: $font-color;
    font: {
        family: $font-family;
        size: $font-size;
        weight: $font-weight;
    }
;

    @include breakpoint(tablet-portrait) {
        font-size: 16px;
    }
}

%body {
    line-height: 1.45;

    h1, h2, h3, h4, h5, h6 {
        @if $header-font-color {
            color: $header-font-color;
        }

        @if $header-font-family {
            font-family: $header-font-family;
        }

        @if $header-font-weight {
            font-weight: $header-font-weight;
        }

        line-height: 1.3;
        margin: 1em 0 .5em;

        + p, + ol, + ul, + blockquote {
            margin-top: -.25em;
        }

        + h1, + h2, + h3, + h4, + h5, + h6 {
            margin-top: -.22em;
        }
    }

    h1 {
        font-size: $h1-font-size;

        @if $h1-font-color {
            color: $h1-font-color;
        }

        @if $h1-font-weight {
            font-weight: $h1-font-weight;
        }

        @if $h1-margin-bottom {
            margin-bottom: $h1-margin-bottom;
        }
    }

    h2 {
        font-size: $h2-font-size;

        @if $h2-font-color {
            color: $h2-font-color;
        }

        @if $h2-font-weight {
            font-weight: $h2-font-weight;
        }

        @if $h2-margin-bottom {
            margin-bottom: $h2-margin-bottom;
        }
    }

    h3 {
        font-size: $h3-font-size;

        @if $h3-font-color {
            color: $h3-font-color;
        }

        @if $h3-font-weight {
            font-weight: $h3-font-weight;
        }

        @if $h3-margin-bottom {
            margin-bottom: $h3-margin-bottom;
        }
    }

    h4 {
        font-size: $h4-font-size;

        @if $h4-font-color {
            color: $h4-font-color;
        }

        @if $h4-font-weight {
            font-weight: $h4-font-weight;
        }

        @if $h4-margin-bottom {
            margin-bottom: $h4-margin-bottom;
        }
    }

    h5 {
        font-size: $h5-font-size;

        @if $h5-font-color {
            color: $h5-font-color;
        }

        @if $h5-font-weight {
            font-weight: $h5-font-weight;
        }

        @if $h5-margin-bottom {
            margin-bottom: $h5-margin-bottom;
        }
    }

    h6 {
        font-size: $h6-font-size;

        @if $h6-font-color {
            color: $h6-font-color;
        }

        @if $h6-font-weight {
            font-weight: $h6-font-weight;
        }

        @if $h6-margin-bottom {
            margin-bottom: $h6-margin-bottom;
        }
    }

    p {
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1, h2, h3, h4, h5, h6, p, ul, ol {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol, ul {
        padding-left: 1.8em;

        li {
            margin-bottom: .4em;
            line-height: 1.3;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ol, ul {
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    ul {
        li {
            position: relative;
            list-style-type: none;
        }

        li:before {
            //color: $list-unordered-icon-color;
            content: '•';
            font-size: 1.3em;
            position: absolute;
            top: .04em;
            right: calc(100% + .3em);
            line-height: 1;
        }

        &.checks {
            li:before {
                display: none;
            }

            @if $list-unordered-checks-icon {
                li:before {
                    content: '';
                    display: inline-block;
                    background: {
                        image: $list-unordered-checks-icon;
                        size: contain;
                        repeat: no-repeat;
                        position: top left;
                    }
                ;
                    width: 1em;
                    height: 1em;
                }
            } @else {
                list-style-type: disc;
            }
        }
    }

    a {
        color: $link-color;
        text-decoration: none;
    }

    #{$all-buttons} {
        @include button;
        text-decoration: none !important;
    }

    em {

    }

    strong {
        font-weight: 700;
    }

    blockquote {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 1.2em;
        font-weight: 300;
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        cite {
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;

        th, td {
            border: 1px solid #e3e3e3;
            padding: .7em 1.1em;
            text-align: left;

            @include breakpoint(phone-landscape) {
                padding: .5em .8em;
            }
        }

        th {
            background-color: $theme-secondary-color;
            border-color: $theme-secondary-color;
            color: set-color-for-background-color($theme-secondary-color);
        }

        &.clean {
            th, td {
                border: 0;
                border: none;
                padding: 0;
            }
        }

        &.opening {
            td {
                vertical-align: top;
                line-height: 1.6;
                font-size: .95em;

                &:first-child {
                    font-weight: bold;
                }
            }
        }
    }
}
