.mod-images {

	ul {
		@include clear-ul;
	}

    figure {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

	&.element-template--default, &.element-template--2-overlapping {

		img {
            box-sizing: border-box;
			margin: 0;
			padding: 8px;
			max-width: 100%;
			height: auto;
			display: block;
            background: #fff;
            box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
		}

		figcaption {
			font-size: 1rem;
		}

		.fluid-columns & {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			height: 100%;
			overflow: hidden;

			ul, li {
				width: 100%;
				height: 100%;
			}

			li:nth-child(n+2) {
				display: none;
			}

			img {
				border-radius: 0;
				position: absolute;
				left: 50%;
				max-height: 100%;
				max-width: none;
				min-height: 100%;
				top: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}

	&.element-template--2-overlapping {
		position: relative;

		li {
			width: 80%;

			&:first-child {
				padding: 0 20% 20% 0;
			}

			&:last-child {
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
	}

	&.element-template--gallery, &.element-template--gallery-big {
		@include clearfix;

		.image-wrapper {
			cursor: pointer;
			float: left;
			overflow: hidden;
			position: relative;
			transform: translateZ(1); // Fixes border radius disappearing during scale animation

			.image {
				background-position: center;
				background-size: cover;
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				transition: transform .4s;
				backface-visibility: hidden;
			}

			img {
				min-width: 100%;
				min-height: 100%;
				position: absolute;
				visibility: hidden;
			}

			&:after {
				background: rgba(#000, .5) url(../../images/gallery/zoom.svg) no-repeat center;
				background-size: 60px 60px;
				content: '';
				display: block;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				transition: opacity .14s;
				backface-visibility: hidden;
			}

			&:hover {
				.image {
					transform: scale(1.15);
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}

	&.element-template--gallery .image-wrapper {
		$width: calc(16.6667% - 1em);
		border-radius: 5px;
		margin: 0 1.2em 1.2em 0;
		padding-bottom: $width;
		width: $width;

		&:after {
			background-size: 50px 50px;
		}

		&:nth-child(6n+6) {
			margin-right: 0;
		}

		&:nth-child(6n+7) {
			clear: both;
		}
	}

	&.element-template--gallery-big .image-wrapper {
		padding-bottom: 25%;
		width: 25%;
	}

	&.element-template--banner {
		position: absolute;
		top: 0; bottom: 0;
		right: 0; left: 0;
		width: 100%;
		height: 100%;
		transform: translateZ(1); // Fixes border radius disappearing during scale animation

		.image {
			box-sizing: border-box;
			background: {
				position: center;
				size: cover;
				repeat: no-repeat;
			}
			height: 100%;
			width: 100%;
			backface-visibility: hidden;
		}
	}
}
