.mod-breadcrumbs {
    @include clearfix;
    min-height: 10px;
    padding-bottom: $orange-margin;

    span, a {
        box-sizing: border-box;
        display: inline-block;
        font-weight: 300;
        color: lighten( $font-color, 15% );
        text-decoration: none;
    }

    a:after {
        content: '';
        background-image: url('../../images/bread.svg');
        background-size: 13px 13px;
        background-position: center bottom;
        background-repeat: no-repeat;
        display: inline-block;
        position: relative;
        top: 2px;
        width: 13px;
        height: 13px;
        margin: 0 .3em;
    }

    a:last-child {
        color: #afafaf;
        pointer-events: none;

        &:after {
            display: none;
        }
    }
}