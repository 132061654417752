.mod-contact-form {
	box-sizing: border-box;
	text-align: left;

	&.element-template--boxed {
        background: #f8f8f8;
        box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
        border-radius: 3px;
        padding: 2em 1.6em;
	}

	.form-error {
		margin-bottom: 1em;
	}

	.form-field {
		//max-width: 540px;
	}

	.form-field-textarea {
		max-width: none;
	}

	&.element-template--one-line {
		position: relative;

		.form-field {
			max-width: none;

			.error {
				display: none;
			}
		}

		input[type="text"], input[type="password"], input[type="email"], textarea, select {
			border: none;
			border-radius: 3px;
			padding: .9333em 1em;
		}

		button[type="submit"] {
			position: absolute;
			bottom: .26em;
			right: .26em;
			padding: .48em .84em;
		}

		.some-fields-required {
			display: none;
		}
	}
}
